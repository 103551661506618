import { useQuery } from '@tanstack/react-query';
import { GeneralPreference } from '@demind-inc/core';

import { accountApi } from '../api';

interface UseGeneralPreferenceParams {
  preferenceId: string;
}

interface UseGeneralPreferenceQueryParams {
  queryKey: ['lifestack.preference.general', UseGeneralPreferenceParams];
}

export const useGeneralPreference = ({ preferenceId }: UseGeneralPreferenceParams) => {
  const generalPrefQuery = useQuery({
    queryKey: ['lifestack.preference.general', { preferenceId }],
    queryFn: ({ queryKey }: UseGeneralPreferenceQueryParams) => {
      const [, { preferenceId }] = queryKey;
      return accountApi
        .getGeneralPreference(preferenceId)
        .then(({ data }) => data as GeneralPreference);
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!preferenceId,
  });

  return {
    generalPreferences: generalPrefQuery.data ?? {},
    ...generalPrefQuery,
  };
};