import { FC } from 'react';
import { ALL_TODO_INTEGRATIONS_TYPE, TodoIntegrationType } from '@demind-inc/core';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { Check, Add, OpenInFull } from '@mui/icons-material';
import { useRecoilState } from 'recoil';

import './TodoIntgSettings.scss';
import { TODO_INTEGRATION_ICONS } from '../../../TodoIntegration';
import {
  AVAILABLE_TODO_INTEGRATIONS_TYPE,
  CannyPostOptionnName,
  visibleFeedbackDialogPostNameAtom,
} from '../../../../data-access';
import { useStartTodoAuth } from '../../../../hooks';

const TodoIntgSettings: FC = () => {
  const { connectedTodos, generatingTodoAuth, handleOpenTodoAuth } = useStartTodoAuth({});
  const [_, setVisibleFeedbackPostName] = useRecoilState(visibleFeedbackDialogPostNameAtom);

  const isComingSoon = (todoType: TodoIntegrationType) =>
    !AVAILABLE_TODO_INTEGRATIONS_TYPE.includes(todoType);

  const handleConnect = (type: TodoIntegrationType) => {
    if (isComingSoon(type)) {
      const postName = {
        todoist: null,
        trello: null,
        ticktick: 'support_ticktick',
        notion: 'support_notion',
      }[type];
      setVisibleFeedbackPostName(postName as CannyPostOptionnName | null);
      return;
    }
    if (!generatingTodoAuth) {
      handleOpenTodoAuth(type);
    }
  };

  const getButtonContent = (
    todoType: TodoIntegrationType,
    connected: boolean,
    notSupported: boolean
  ) => {
    if (generatingTodoAuth === todoType) {
      return <CircularProgress size={15} className="todo-intg-settings__loading" />;
    }

    const icon = notSupported ? (
      <OpenInFull fontSize="inherit" />
    ) : connected ? (
      <Check fontSize="inherit" />
    ) : (
      <Add fontSize="inherit" />
    );
    let text = 'Connect';
    if (connected) text = 'Connected';
    if (!connected && notSupported) text = 'Coming soon';

    return (
      <>
        {icon}
        {text}
        <img
          src={TODO_INTEGRATION_ICONS[todoType]}
          alt={todoType}
          className="todo-intg-settings__icon"
        />
      </>
    );
  };

  const getButtonClassName = (connected: boolean) =>
    clsx('todo-intg-settings__button', {
      'todo-intg-settings__button--connected': connected,
      'todo-intg-settings__button--connectable': !connected,
    });

  return (
    <div className="todo-intg-settings">
      <div className="todo-intg-settings__title">Todos</div>
      <div className="todo-intg-settings__grid">
        {ALL_TODO_INTEGRATIONS_TYPE.map((todoType) => {
          const connected = connectedTodos.some(({ source }) => source === todoType);

          return (
            <button
              key={todoType}
              disabled={connected}
              onClick={() => handleConnect(todoType)}
              className={getButtonClassName(connected)}
            >
              <div className="todo-intg-settings__button-content">
                {getButtonContent(todoType, connected, isComingSoon(todoType))}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default TodoIntgSettings;
