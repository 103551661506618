import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  GoogleCallback,
  OutlookCallback,
  TerraCallback,
  TodoistAuthCallback,
  TrelloAuthCallback,
} from '../pages';

export const CallbackRoutes: FC = () => {
  return (
    <Routes>
      <Route path={'todoist'} element={<TodoistAuthCallback />} />
      <Route path={'trello'} element={<TrelloAuthCallback />} />
      <Route path={'google'} element={<GoogleCallback />} />
      <Route path={'outlook'} element={<OutlookCallback />} />
      <Route path={'terra'} element={<TerraCallback />} />
    </Routes>
  );
};
