import React, { useMemo } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { TaskItem } from '@demind-inc/core';

import './KanbanView.scss';
import {
  KanbanList,
  ProjectFilter,
  ManageProjectsBtn,
  AddProjectDialog,
} from '../../../components';
import { useTodoTasksContext, useFetchOverdueTasks } from '../../../data-access';
import { useStartTodoAuth, useProjectFilter, useMoveTask, useAddProject } from '../../../hooks';

interface KanbanViewProps {
  className?: string;
}

const KanbanView: React.FC<KanbanViewProps> = ({ className }) => {
  const {
    todoTasksByDate,
    visibleTodoListDates,
    todoProjects,
    isFetchingTasks,
    isFetchingProjects,
    visibleProjectIds,
  } = useTodoTasksContext();

  const { handleOpenTodoAuth, generatingTodoAuth } = useStartTodoAuth({});
  const { selectedFilter, filterProjectOptions, handleSelectFilterProject } = useProjectFilter();
  const { handleMoveTask } = useMoveTask();
  const { projectDialogVisible, isAddingProject, handleProjectDialogVisible, handleAddProject } =
    useAddProject();

  const dueDateTill = useMemo(
    () => dayjs(visibleTodoListDates[0]).subtract(1, 'day').format('YYYY-MM-DD'),
    [visibleTodoListDates]
  );

  const { data: overdueTasks } = useFetchOverdueTasks({
    visibleProjectIds,
    dueDateTill,
  });

  const hasNoProjects = !todoProjects.length && !isFetchingProjects;

  const renderKanbanList = (listDate: dayjs.Dayjs, tasks: TaskItem[], isOverDue = false) => (
    <KanbanList
      key={listDate.toISOString()}
      date={listDate}
      tasks={tasks}
      isFetchingTasks={isFetchingTasks}
      generatingTodoAuth={generatingTodoAuth}
      isOverDue={isOverDue}
      className="kanban-view__content__list"
      onConnectTodo={handleOpenTodoAuth}
      onMoveTask={(task) => handleMoveTask({ task, targetDate: listDate, wasOverdue: isOverDue })}
    />
  );

  return (
    <div className={clsx('kanban-view', className)}>
      <div className="kanban-view__header">
        <div className="kanban-view__header__left-group">
          <div className="kanban-view__header__title">
            {dayjs(visibleTodoListDates[0]).format('MMM D')}
          </div>
        </div>
        {hasNoProjects ? (
          <ManageProjectsBtn onConnect={handleOpenTodoAuth} />
        ) : (
          <ProjectFilter
            filterOptions={filterProjectOptions}
            selectedFilter={selectedFilter}
            onProjectAdd={() => handleProjectDialogVisible(true)}
            onSelectFilter={handleSelectFilterProject}
          />
        )}
      </div>
      <div
        className={clsx('kanban-view__content', {
          'kanban-view__content--grid-4': overdueTasks?.length,
        })}
      >
        {overdueTasks && overdueTasks.length > 0 && (
          <>{renderKanbanList(dayjs(visibleTodoListDates[0]), overdueTasks as TaskItem[], true)}</>
        )}
        {visibleTodoListDates.map((listDate) =>
          renderKanbanList(
            listDate,
            todoTasksByDate.find(({ date }) => date === listDate.format('YYYY-MM-DD'))?.tasks || []
          )
        )}
      </div>
      <AddProjectDialog
        visible={projectDialogVisible}
        isAdding={isAddingProject}
        onAdd={handleAddProject}
        onClose={() => handleProjectDialogVisible(false)}
      />
    </div>
  );
};

export default KanbanView;
