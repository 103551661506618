import React, {
  FC,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { Calendar, momentLocalizer, SlotInfo } from 'react-big-calendar';
import dayjs from 'dayjs';
import moment from 'moment';
import withDragAndDrop, {
  DragFromOutsideItemArgs,
  EventInteractionArgs,
} from 'react-big-calendar/lib/addons/dragAndDrop';

import './CalendarTimeline.scss';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useRecoilValue } from 'recoil';

import { EventBlock } from './EventBlock';
import { RBCEvent } from './types';
import { CustomTimeGutter } from './CustomTimeGutter';
import {
  PeakDipPhase,
  selectedDateAtom,
  useCalendarContext,
  useCircadianContext,
  useCreateEventMenuContext,
  useEventDetailsMenuContext,
} from '../../../data-access';
import { CALENDAR_STEP_MIN, zoneColorForPeaksDipsPhase } from './constants';
import { getCssVariable } from '../../../utils';
import { CreateEventMenu, EditEventMenu, EventDetailsMenu } from '../EventMenu';
import { transformCreatingEventToRBCEvent } from './helpers';
import { useGeneralSettings } from '../../../hooks/useGeneralSettings';
import { useDnDTaskToCalendar } from '../../../hooks';

interface CalendarTimelineProps {
  events: RBCEvent[];
  hasNoSleepData?: boolean;
  onEventDrop: (data: EventInteractionArgs<object>) => void;
  onEventResize: (data: EventInteractionArgs<object>) => void;
  className?: string;
}

interface MemoizedCalendarProps extends CalendarTimelineProps {
  scrollToTime: Date;
  dragFromOutsideItem: () => Partial<RBCEvent>;
  onDropEventFromOutside: (data: DragFromOutsideItemArgs) => void;
  onSelectEvent: (event: RBCEvent) => void;
  onSelectSlot: (slot: SlotInfo) => void;
}

const localizer = momentLocalizer(moment); // dayjsLocalizer is so slow.
const DnDCalendar = withDragAndDrop(Calendar);
const primaryColor = getCssVariable('--color-primary');

const MemoizedCalendar = React.memo(
  forwardRef<HTMLDivElement, MemoizedCalendarProps>(
    (
      {
        events,
        scrollToTime,
        hasNoSleepData = false,
        onEventDrop,
        onEventResize,
        dragFromOutsideItem,
        onDropEventFromOutside,
        onSelectEvent,
        onSelectSlot,
        className,
      },
      ref
    ) => {
      const { peaksDipsBoundaries, circadianRhythms } = useCircadianContext();
      const selectedDate = useRecoilValue(selectedDateAtom);
      const {
        generalSettings: { timeFormat },
      } = useGeneralSettings();
      const selectedTimeFormat = timeFormat === '12h' ? 'h a' : 'HH';

      const backgroundEvents = useMemo(
        () =>
          peaksDipsBoundaries
            ? Object.entries(peaksDipsBoundaries).map(([phase, { start, end }]) => ({
                start: new Date(start),
                end: new Date(end),
                color: zoneColorForPeaksDipsPhase[phase as PeakDipPhase],
              }))
            : [],
        [peaksDipsBoundaries]
      );

      return (
        <div ref={ref} className="calendar-timeline__container">
          <DnDCalendar
            date={selectedDate.toDate()}
            defaultView="day"
            events={events}
            localizer={localizer}
            onEventDrop={onEventDrop}
            onSelectEvent={(event) => onSelectEvent(event as RBCEvent)}
            onEventResize={onEventResize}
            //@ts-ignore
            dragFromOutsideItem={dragFromOutsideItem}
            onDropFromOutside={onDropEventFromOutside}
            onDragOver={(e) => e.preventDefault()}
            onSelectSlot={onSelectSlot}
            resizable
            selectable
            showMultiDayTimes
            step={CALENDAR_STEP_MIN}
            dayLayoutAlgorithm="no-overlap"
            scrollToTime={scrollToTime}
            formats={{
              timeGutterFormat: (date) => {
                if (dayjs(date).minute() === 0) {
                  return dayjs(date).format(selectedTimeFormat);
                }
              },
            }}
            backgroundEvents={backgroundEvents}
            components={{
              timeGutterWrapper: (props: React.PropsWithChildren<{}>) => {
                return (
                  <CustomTimeGutter
                    peaksDipsBoundaries={peaksDipsBoundaries}
                    heatmapData={circadianRhythms}
                    isDefaultHeatmap={hasNoSleepData}
                  >
                    {props.children}
                  </CustomTimeGutter>
                );
              },
              event: (event) => <EventBlock event={event.event} />,
            }}
            toolbar={false}
            eventPropGetter={(event) => {
              const rbcEvent = event as RBCEvent;
              if (rbcEvent.eventId) {
                // Calendar Event
                const isCreatingEvent = rbcEvent.eventId === 'creating';
                return {
                  style: {
                    backgroundColor: isCreatingEvent
                      ? 'white'
                      : `${rbcEvent.color || primaryColor}33`,
                    border: isCreatingEvent ? `solid 1px ${primaryColor}` : 'none',
                    padding: 0,
                  },
                };
              } else {
                // Zone
                return {
                  style: {
                    backgroundColor: `${rbcEvent.color}1A`,
                    border: `1px solid ${rbcEvent.color}`,
                    borderRadius: '0',
                    borderLeft: 'none',
                    borderRight: 'none',
                  },
                };
              }
            }}
            className={clsx('calendar-timeline', className)}
          />
        </div>
      );
    }
  )
);

const CalendarTimeline: FC<CalendarTimelineProps> = memo(
  ({ events, hasNoSleepData = false, onEventDrop, onEventResize, className }) => {
    const { creatingEvent, handleSelectSlot, clearCreatingEvent } = useCreateEventMenuContext();
    const { visibleMenuMode, selectedEvent, handleSelectEvent, clearSelectedEvent } =
      useEventDetailsMenuContext();
    const { mainCalendar } = useCalendarContext();
    const selectedDate = useRecoilValue(selectedDateAtom);
    const scrollToTime = useRef<Date>(selectedDate.toDate());

    const {
      draggedNewEvent: defaultDraggedEvent,
      dropTaskOnCalendar,
      handleDropEventFromOutside: handleDropEventFromOutsideApi,
    } = useDnDTaskToCalendar();
    const hasDraggedEvent = useRef(false);
    const [draggedEvent, setDraggedEvent] = useState<Partial<RBCEvent> | null>(null);
    const [rerenderTrigger, setRerenderTrigger] = useState(0); // New state to trigger rerenders

    const eventWithCreatingOne = useMemo(
      () => (creatingEvent ? [...events, transformCreatingEventToRBCEvent(creatingEvent)] : events),
      [creatingEvent, events]
    );

    // Initialize dragged event
    useEffect(() => {
      if (hasDraggedEvent.current || !defaultDraggedEvent) {
        return;
      }

      setDraggedEvent({
        title: defaultDraggedEvent.summary,
        eventId: 'dragging',
        color: mainCalendar.color || primaryColor,
      });
      hasDraggedEvent.current = true;
    }, [defaultDraggedEvent, mainCalendar]);

    const handleDropEventFromOutside = (data: DragFromOutsideItemArgs) => {
      handleDropEventFromOutsideApi(data);
      setDraggedEvent(null);
      hasDraggedEvent.current = false;
      scrollToTime.current = dayjs(data.start).toDate();
      setRerenderTrigger((prev) => prev + 1); // Force re-rendering calendar after dragging & dropping, so it enables to resize right away TODO: asking it on the community (https://github.com/jquense/react-big-calendar/issues/2678)
    };

    const dragFromOutsideItem = useCallback(() => draggedEvent, [draggedEvent]);

    return (
      <>
        <MemoizedCalendar
          key={rerenderTrigger}
          ref={dropTaskOnCalendar}
          events={eventWithCreatingOne}
          hasNoSleepData={hasNoSleepData}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          onSelectEvent={handleSelectEvent}
          onDropEventFromOutside={handleDropEventFromOutside}
          onSelectSlot={handleSelectSlot}
          dragFromOutsideItem={dragFromOutsideItem}
          scrollToTime={scrollToTime.current} // Scroll to dragged event's position after re-rendering by drag&drop
          className={className}
        />
        <CreateEventMenu visible={!!creatingEvent} onClose={clearCreatingEvent} />
        <EventDetailsMenu
          visible={!!selectedEvent && visibleMenuMode === 'details'}
          onClose={clearSelectedEvent}
        />
        <EditEventMenu
          visible={!!selectedEvent && visibleMenuMode === 'edit'}
          onClose={clearSelectedEvent}
        />
      </>
    );
  }
);

export default CalendarTimeline;
