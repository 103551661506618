import { useState } from 'react';

import { getArrayOfXDaysBackFromToday, useAuthContext } from '../data-access';
import { useSyncSleepData } from './useSyncSleepData';

const SYNCED_DAYS = 20;

export const useInitSleepData = () => {
  const { user, updateUser } = useAuthContext();
  const { syncSleepData: syncSleepDataEndpoint } = useSyncSleepData();

  const [isSyncing, setIsSyncing] = useState(false);

  const syncedDates = getArrayOfXDaysBackFromToday(SYNCED_DAYS);

  const syncSleepData = async () => {
    if (!user.terraUserIds?.length || !user.metricId) {
      await updateUser({
        hasInitialSynced: true,
      });
      return;
    }

    setIsSyncing(true);
    try {
      await syncSleepDataEndpoint({
        terraUserIds: user.terraUserIds ?? [],
        startDate: syncedDates[0].format('YYYY-MM-DD'),
        endDate: syncedDates[syncedDates.length - 1].format('YYYY-MM-DD'),
      });
    } finally {
      await updateUser({
        hasInitialSynced: true,
      });

      setIsSyncing(false);
    }
  };

  return {
    isSyncing,
    syncSleepData,
  };
};
