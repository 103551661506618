import { useQuery } from '@tanstack/react-query';
import { tasksApi } from '../api';
import { TaskItem } from '@demind-inc/core';

interface FetchOverdueTasksParams {
  visibleProjectIds: string[];
  dueDateTill: string;
}

export const useFetchOverdueTasks = (params: FetchOverdueTasksParams) => {
  return useQuery({
    queryKey: ['lifestack.todo.overdueTasks', params],
    queryFn: () =>
      tasksApi
        .getOverdueTodoTasks(params.visibleProjectIds, params.dueDateTill)
        .then(({ data }) => data as TaskItem[]),
    enabled: params?.visibleProjectIds?.length > 0 && !!params.dueDateTill,
  });
};
