import { useState } from 'react';
import { CalendarType } from '@demind-inc/core';

import { calendarApi, googleCalAuth } from '../data-access';
import { trackEventGA4 } from '../utils';

export const useConnectCalendar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const connectCal = async (type: CalendarType) => {
    try {
      setIsLoading(true);
      if (type === 'google') {
        await googleCalAuth.signinRedirect();
      } else if (type === 'outlook') {
        const redirectUri = `${window.location.origin}/callback/outlook`;
        const url = (await calendarApi.generateOutlookAuthUrl(redirectUri, 'v2.0')).data;
        window.location.href = url;
      }
      setIsLoading(false);
      trackEventGA4('Button_click', 'connect_calendar', { type });
    } catch (err) {
      setIsLoading(false);
      setError('Something wrong while connecting google calendars');
    }
  };

  return { connectCal, isLoading, error };
};
