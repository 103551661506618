import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { TodoTasksBoard } from '@demind-inc/core';
import dayjs, { Dayjs } from 'dayjs';
import { useRecoilValue } from 'recoil';

import { useAuthContext } from './AuthProvider';
import { useTodoProjects, useTodoTasks } from '../queries';
import { useSyncTodoTasks } from '../mutations';
import { projectFilterAtom, selectedDateAtom } from '../recoil';
import { TasksByDate } from '../types';

interface ITodoTasksContext {
  todoProjects: TodoTasksBoard[];
  todoTasksByDate: TasksByDate[];
  visibleTodoListDates: Dayjs[];
  isSyncingTodos: boolean;
  isFetchingProjects: boolean;
  isFetchingTasks: boolean;
  reSyncTasks: (targetTodoIntegrationIds: string[]) => void;
  findProject: (projectId: string) => TodoTasksBoard | undefined;
  visibleProjectIds: string[];
}

export const TodoTasksContext = createContext({} as ITodoTasksContext);
export const useTodoTasksContext = () => useContext(TodoTasksContext);

const numOfDaysForTasksList = 3;

export const TodoTasksProvider = ({ children }: { children: ReactNode }) => {
  const selectedDate = useRecoilValue(selectedDateAtom);
  const selectedFilter = useRecoilValue(projectFilterAtom);

  const { user } = useAuthContext();
  const { todoProjects, isLoading: isFetchingProjects } = useTodoProjects({ userId: user.userId! });
  const { syncTodoTasks, isPending: isSyncingTodos } = useSyncTodoTasks();

  const visibleProjectIds = useMemo(() => {
    if (selectedFilter?.todoTaskId === 'all') {
      return user?.todoTasksBoardIds;
    }
    return selectedFilter?.todoTaskId ? [selectedFilter.todoTaskId] : [];
  }, [selectedFilter, user?.todoTasksBoardIds?.length]);
  const visibleTodoListDates = useMemo(
    () =>
      Array.from({ length: numOfDaysForTasksList }).map((_, index) =>
        selectedDate.add(index, 'day')
      ),
    [selectedDate]
  );
  const { todoTasksByDate, isLoading: isFetchingTasks } = useTodoTasks({
    todoTasksBoardIds: visibleProjectIds ?? [],
    dates: visibleTodoListDates.map((d) => d.format('YYYY-MM-DD')),
  });

  // Sync the todo tasks
  useEffect(() => {
    if (!user.userId || !user.todoIntegrationIds?.length) {
      return;
    }

    const targetTodoIntegrationIds = user.todoIntegrationIds
      .map((ref) => (ref as any)?._path?.segments[1])
      .filter((i) => !!i) as string[]; //TODO: #383 Get string[] returned from the endpoint.

    syncTodoTasks({
      userId: user.userId,
      timezone: dayjs.tz.guess(),
      targetTodoIntegrationIds,
    });
  }, [user.userId, user.todoIntegrationIds]);

  const reSyncTasks = useCallback(
    (targetTodoIntegrationIds: string[]) => {
      syncTodoTasks({
        userId: user.userId,
        targetTodoIntegrationIds,
        timezone: dayjs.tz.guess(),
      });
    },
    [user]
  );

  const findProject = useCallback(
    (projectId: string) => {
      if (!todoProjects.length) {
        return;
      }
      const project = todoProjects.find((project) => project.todoTaskId === projectId);
      return project;
    },
    [todoProjects.length]
  );

  return (
    <TodoTasksContext.Provider
      value={{
        todoProjects,
        todoTasksByDate,
        visibleTodoListDates,
        isSyncingTodos,
        isFetchingProjects,
        isFetchingTasks,
        reSyncTasks,
        findProject,
        visibleProjectIds,
      }}
    >
      {children}
    </TodoTasksContext.Provider>
  );
};
