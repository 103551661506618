import { atom } from 'recoil';
import dayjs, { Dayjs } from 'dayjs';
import { TaskItem } from '@demind-inc/core';
import { ProjectFilterItem } from '../../hooks';
import { AxiosResponse } from 'axios';
import { TaskErrorInfo } from '../types';
import { CannyPostOptionnName } from '../hooks';

export const selectedDateAtom = atom<Dayjs>({
  key: 'selectedDate',
  default: dayjs(),
});

export const visibleTaskDetailAtom = atom<TaskItem | null>({
  key: 'visibleTaskDetail',
  default: null,
});

export const eventsSnackBarAtom = atom<string>({
  key: 'eventsSnackBar',
  default: '',
});

export const taskErrorSnackBarAtom = atom<TaskErrorInfo | null>({
  key: 'taskErrorSnackBar',
  default: null,
});

export const allTypeProjectFilter: ProjectFilterItem = {
  todoTaskId: 'all',
  name: 'All',
};

export const projectFilterAtom = atom<ProjectFilterItem>({
  key: 'projectFilter',
  default: allTypeProjectFilter,
});

export const visibleFeedbackDialogPostNameAtom = atom<CannyPostOptionnName | null>({
  key: 'visibleFeedbackDialogPostName',
  default: null,
});
