import { CalendarEvent } from '@demind-inc/core';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { SlotInfo } from 'react-big-calendar';
import { debounce, isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilState } from 'recoil';

import { useCreateCalendarEvent } from '../mutations';
import { CalendarEventCreateOption } from '../types';
import { useAuthContext } from './AuthProvider';
import { useCalendarContext } from './CalendarProvider';
import { eventsSnackBarAtom } from '../recoil';
import { trackEventGA4 } from '../../utils';

interface ICreateEventMenuContext {
  creatingEvent?: Partial<CalendarEvent>;
  handleSelectSlot: (slot: SlotInfo) => void;
  handleCreatingEventChange: (event: Partial<CalendarEvent>) => void;
  handleCreateEvent: (newEvent: CalendarEventCreateOption) => void;
  clearCreatingEvent: () => void;
}

export const CreateEventMenuContext = createContext({} as ICreateEventMenuContext);
export const useCreateEventMenuContext = () => useContext(CreateEventMenuContext);

export const CreateEventMenuProvider = ({ children }: { children: ReactNode }) => {
  const [creatingEvent, setCreatingEvent] = useState<Partial<CalendarEvent> | undefined>();
  const [_, setEventsSnackbar] = useRecoilState(eventsSnackBarAtom);

  const { createCalendarEvent, status: eventCreatingStatus } = useCreateCalendarEvent();
  const { user } = useAuthContext();
  const { mainCalendar } = useCalendarContext();

  useEffect(() => {
    if (eventCreatingStatus === 'success') {
      setEventsSnackbar('Event created');
    } else if (eventCreatingStatus === 'pending') {
      setEventsSnackbar('Event creating...');
    } else if (eventCreatingStatus === 'error') {
      setEventsSnackbar('Failed to create event');
    }
  }, [eventCreatingStatus]);

  const handleCreatingEventChange = debounce((event: Partial<CalendarEvent>) => {
    setCreatingEvent((prev) => ({
      ...prev,
      ...event,
    }));
  }, 500);

  const handleSelectSlot = (slot: SlotInfo) => {
    setCreatingEvent({
      eventId: 'creating',
      start: { date: slot.start.toISOString(), timeZone: dayjs.tz.guess() },
      end: {
        date: slot.end.toISOString(),
        timeZone: dayjs.tz.guess(),
      },
    });
  };

  const handleCreateEvent = (newEvent: CalendarEventCreateOption) => {
    if (isEmpty(newEvent)) {
      return;
    }

    const newEventId = uuidv4().replace(/-/g, '');
    const color = mainCalendar?.color;
    const newEventOption = {
      ...newEvent,
      color,
      eventId: newEventId,
    };

    createCalendarEvent({
      userId: user.userId,
      calendarId: newEvent?.calendarId || mainCalendar?.calendarId,
      newEventOption,
    });
    trackEventGA4('Button_click', 'create_calendar_event_on_calendar', {
      event: newEventOption.summary,
    });
    clearCreatingEvent();
  };

  const clearCreatingEvent = () => {
    setCreatingEvent(undefined);
  };

  return (
    <CreateEventMenuContext.Provider
      value={{
        creatingEvent,
        handleSelectSlot,
        handleCreateEvent,
        clearCreatingEvent,
        handleCreatingEventChange,
      }}
    >
      {children}
    </CreateEventMenuContext.Provider>
  );
};
