import { useState } from 'react';
import { externalApi, UpdateCannyPostUpdates } from '../api';
import { useAuthContext } from '../context';
import { trackEventGA4 } from '../../utils';

interface UpdateCannyPostParams {
  nickname: string;
  postName: CannyPostOptionnName;
  updates: UpdateCannyPostUpdates;
}

export type CannyPostOptionnName =
  | 'support_ticktick'
  | 'support_notion'
  | 'ai_scheduler'
  | 'three_day_view'
  | 'week_view'
  | 'exercise_tracking'
  | 'food_tracking';

export const postIdsNameMap: Record<CannyPostOptionnName, { id: string; url: string }> = {
  support_ticktick: {
    id: '6615940e2d150c682f5b293b',
    url: 'https://lifestack.canny.io/admin/board/feature-requests/p/ticktick-integration',
  },
  support_notion: {
    id: '67338a41af35186c31ba7ba2',
    url: 'https://lifestack.canny.io/admin/board/feature-requests/p/notion-database-integration',
  },
  ai_scheduler: {
    id: '667b271ad288d9176ec474d4',
    url: 'https://lifestack.canny.io/admin/board/feature-requests/p/ai-task-scheduler',
  },
  three_day_view: {
    id: '673568070989a4825f453fda',
    url: 'https://lifestack.canny.io/admin/board/feature-requests/p/3-day-view-in-web-apps-calendar',
  },
  week_view: {
    id: '673568237bc896ccd7d03a00',
    url: 'https://lifestack.canny.io/admin/board/feature-requests/p/week-view-in-web-apps-calendar',
  },
  exercise_tracking: {
    id: '6735686c7bc896ccd7d07bf3',
    url: 'https://lifestack.canny.io/admin/board/feature-requests/p/exercise-tracking',
  },
  food_tracking: {
    id: '673568772fdffe2950344ddd',
    url: 'https://lifestack.canny.io/admin/board/feature-requests/p/food-tracking',
  },
};

export const useUpdateCannyPost = () => {
  const { user } = useAuthContext();
  const [isPosting, setIsPosting] = useState(false);

  const updateCannyPost = async ({ nickname, postName, updates }: UpdateCannyPostParams) => {
    if (!user.email || !postIdsNameMap[postName]) {
      return;
    }
    setIsPosting(true);
    try {
      await externalApi.updateCannyPost({
        userId: user.userId,
        userEmail: user.email,
        userName: nickname,
        postId: postIdsNameMap[postName].id,
        updates,
      });
      trackEventGA4('Button_click', 'vote_upcoming_feature', { feature: postName, ...updates });
    } finally {
      setIsPosting(false);
    }
  };

  return { isUpdatingCannyPost: isPosting, updateCannyPost };
};
