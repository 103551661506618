import React, { useState, useCallback } from 'react';
import { useAuthContext, useTerraWidget } from '../../../../data-access';
import { MobileSdkOnlyWearbales, WEARABLES } from '../../constants';
import { Path } from '../../../../routing';
import './WearablesSettings.scss';
import { WearableButton } from './WearableButton';

const WearablesSettings: React.FC = () => {
  const { terraWidget, isPending: isGeneratingWidget } = useTerraWidget();
  const [clickedId, setClickedId] = useState<string | null>(null);
  const { user } = useAuthContext();
  const { connectedDevices, metricId } = user;

  const handleClick = (id: string) => {
    setClickedId(id);
    terraWidget({ metricId, redirectTo: Path.DASHBOARD });
  };

  const getIsConnected = useCallback(
    (id: string) => connectedDevices?.some((device) => device.deviceName?.toLowerCase() === id),
    [connectedDevices]
  );

  return (
    <div className="wearables-settings">
      <p className="wearables-settings__title">Wearables</p>
      <div className="wearables-settings__grid">
        {WEARABLES?.map(({ id, name, Icon }) => (
          <WearableButton
            key={id}
            id={id}
            name={name}
            Icon={Icon}
            isConnected={getIsConnected(id)}
            isOnlyMobileSdkSupport={MobileSdkOnlyWearbales.includes(id)}
            isLoading={isGeneratingWidget && clickedId === id}
            onClick={handleClick}
          />
        ))}
      </div>
    </div>
  );
};

export default WearablesSettings;
